<div *ngIf="(actions && actions.length > 0) || dynamicActions" [ngClass]="{ 'container--hover': hover }" class="action-anchor">
  <div *ngIf="actions" class="direct-actions">
    <div class="gradient"></div>
    <div class="buttons">
      <qp-button
        (buttonClick)="onActionClick(firstAction, $event)"
        *ngIf="firstAction"
        [size]="buttonSizes.MEDIUM"
        [commands]="firstAction.getLink ? firstAction.getLink(data) : null"
        [queryParams]="firstAction.getQueryParams ? firstAction.getQueryParams(data) : null"
        [type]="buttonTypes.SECONDARY"
        class="direct-actions__button"
      >
        <qp-button-label>{{ firstAction.label | translate }}</qp-button-label>
      </qp-button>
      <qp-button
        (buttonClick)="onActionClick(secondAction, $event)"
        *ngIf="secondAction"
        [size]="buttonSizes.MEDIUM"
        [commands]="secondAction.getLink ? secondAction.getLink(data) : null"
        [queryParams]="firstAction.getQueryParams ? firstAction.getQueryParams(data) : null"
        [type]="buttonTypes.PRIMARY"
        class="direct-actions__button"
      >
        <qp-button-label>{{ secondAction.label | translate }}</qp-button-label>
      </qp-button>
    </div>
  </div>
  <qp-dot-menu *ngIf="actions && actions?.length > 0" [actions]="actions" [rowData]="data" class="action-menu"></qp-dot-menu>
</div>
