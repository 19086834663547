import { Params } from '@angular/router';

/**
 * @description
 * Using an enum for TProperty is gladly encouraged
 */
export interface IQpDatatableColumnConfigurations<TProperty extends string = string> {
  property: TProperty;
  configurations: IQpDatatableColumnConfiguration[];
}

export interface IQpDatatableColumnConfiguration {
  type: EQpDatatableConfigurationType;
  values?: IQpDatatableColumnConfigurationValue[];
}

export interface IQpDatatableColumnConfigurationValue {
  label: string;
  value: string;
}

/**
 * @description
 * Using an enum for TCellConfigurationName is gladly encouraged
 */
export type QpDatatableCellsConfigurationType<TCellConfigurationName extends string = string> = Record<
  TCellConfigurationName,
  IQpDatatableCellsConfiguration
>;

export interface IQpDatatableCellsConfiguration {
  style?: IQpDatatableCellConfigurationStyle | undefined;
}

/**
 * @description
 * Any option that could change the UI
 */
export interface IQpDatatableCellConfigurationStyle {
  /**
   * @description
   * Usually percentages
   * @example
   * '15%'
   */
  width?: string;
}

export enum EQpDatatableConfigurationType {
  FILTER = 'FILTER',
  SEARCH = 'SEARCH',
  SORT = 'SORT',
  BETWEEN = 'BETWEEN',
}

export interface IQpDatatableFilterAndSortParam extends Params {
  type: string;
  value: string;
}

export interface IQpDatatableFiltersAndSorts {
  [property: string]: IQpDatatableFilterAndSort;
}

export interface IQpDatatableFilterAndSort {
  sort?: 'ASC' | 'DESC';
  search?: string;
  exactSearch?: boolean;
  filter?: string[];
  between?: IQpDatatableFilterAndSortBetween;
}

export interface IQpDatatableFilterAndSortBetween {
  from?: string;
  to?: string;
}
