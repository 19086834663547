import { IQpDatatableFilterAndSort, IQpDatatableFiltersAndSorts } from '@library/components/qp-datatable/qp-datatable.models';
import { qpHasFilterAndSort } from '@library/functions/table/qp-has-filter-and-sort';
import { Pipe, PipeTransform } from '@angular/core';
import { some } from 'lodash/index';

@Pipe({
  name: 'qpHasFiltersAndSorts',
  pure: false,
  standalone: true,
})
export class QpHasFiltersAndSortsTablePipe implements PipeTransform {
  public transform(filtersAndSorts: Readonly<IQpDatatableFiltersAndSorts>): boolean {
    return some(filtersAndSorts, (filterAndSort: Readonly<IQpDatatableFilterAndSort>): boolean => qpHasFilterAndSort(filterAndSort));
  }
}
