import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { Params, ParamMap, convertToParamMap } from '@angular/router';

/**
 * @param params
 */
export function qpFiltersAndSortsFrom(params: Params): IQpDatatableFilterAndSortParam[] {
  const paramsAsMap: ParamMap = convertToParamMap(params);
  const filters: IQpDatatableFilterAndSortParam[] = paramsAsMap.getAll('filter').map((value): IQpDatatableFilterAndSortParam => {
    const filter: IQpDatatableFilterAndSortParam = {
      type: 'filter',
      value,
    };

    return filter;
  });
  const sorts: IQpDatatableFilterAndSortParam[] = paramsAsMap.getAll('sort').map((value): IQpDatatableFilterAndSortParam => {
    const sort: IQpDatatableFilterAndSortParam = {
      type: 'sort',
      value,
    };

    return sort;
  });

  return [...filters, ...sorts];
}
