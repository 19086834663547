import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { qpIsFilterAndSort } from '@library/functions/sorts/qp-is-filter-and-sort';

/**
 * @param data
 */
export function qpAreFiltersAndSorts<T>(data: T | IQpDatatableFilterAndSortParam[]): data is IQpDatatableFilterAndSortParam[] {
  if (data instanceof Array) {
    return data.every((element): boolean => qpIsFilterAndSort<T>(element));
  }

  return false;
}
