import {
  IQpDatatableFilterAndSort,
  IQpDatatableFilterAndSortParam,
  IQpDatatableFiltersAndSorts,
} from '@library/components/qp-datatable/qp-datatable.models';
import {
  EQpTableCellSize,
  EQpTableColumnIdentifier,
  EQpTableColumnMode,
  EQpTableConfigurationType,
  EQpTableFilterType,
  EQpTableName,
  IQpTable,
  IQpTableColumn,
  IQpTableFilterAndSortParam,
  IQpTableFiltersAndSorts,
  IQpTableHeaderAction,
  IQpTableHeaderActionEntry,
} from '@library/components/qp-table/qp-table.models';
import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { ETableCustomType, ITableColumnDTO, ITableColumnsDTO } from '@library/dto/table/table.dto';
import { qpFiltersAndSortsFrom } from '@library/functions/filters/qp-filters-and-sorts-from';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpHasFilters } from '@library/functions/filters/qp-has-filters';
import { qpGetSorts } from '@library/functions/sorts/qp-get-sorts';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { qpTransformFilterToQpTableConfig } from '@library/functions/table/qp-transform-filter-to-qp-table-config';
import { qpTransformSortToQpTableConfig } from '@library/functions/table/qp-transform-sort-to-qp-table-config';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';
import { flatMap, isEmpty, isNil } from 'lodash/index';
import { CookieService } from 'ngx-cookie';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable()
export class QpTableService {
  public isCustomizePanelOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _resourceUrl = `api`;

  public constructor(
    private readonly _qpQueryParamsService: QpQueryParamsService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _httpClient: HttpClient,
    private readonly _qpDateService: QpDateService,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _cookieStorage: CookieService
  ) {}

  public getTableSettings$(tableName: EQpTableName, columnConfiguration: IQpTableColumn[]): Observable<IQpTableColumn[]> {
    const url = `${this._resourceUrl}/settings/data-tables/${tableName}`;

    return this._httpClient.get<ITableColumnsDTO>(url, { observe: 'body' }).pipe(
      map((storedTableColumns: ITableColumnsDTO): IQpTableColumn[] => {
        // We use a flatMap to automatically remove any column coming from the BE that the front does not know using 'return []'
        return flatMap(storedTableColumns.columns, (storedTableColumn: ITableColumnDTO): IQpTableColumn[] => {
          const tableConfigurationColumn: QimaOptionalType<IQpTableColumn> = columnConfiguration.find(
            (tableColumn: IQpTableColumn): boolean => tableColumn.key === storedTableColumn.key
          );

          if (tableConfigurationColumn) {
            return [
              {
                ...tableConfigurationColumn,
                columnMode: storedTableColumn.sticky ? EQpTableColumnMode.STICKY_RIGHT : tableConfigurationColumn.columnMode,
                isDefault: storedTableColumn.default,
                isVisible: storedTableColumn.visible,
              },
            ];
          } else if (storedTableColumn.custom) {
            return [
              {
                label: `${storedTableColumn.label}`,
                size: EQpTableCellSize.M,
                columnMode: EQpTableColumnMode.DEFAULT,
                isDefault: storedTableColumn.default,
                isVisible: storedTableColumn.visible,
                isCustom: storedTableColumn.custom,
                customType: storedTableColumn.type,
                key: storedTableColumn.key,
                header: {
                  propertyName: `customFields.${storedTableColumn.key}`,
                  actions:
                    storedTableColumn.type === ETableCustomType.BOOLEAN
                      ? [
                          {
                            type: EQpTableConfigurationType.FILTER,
                            values: [
                              {
                                value: 'true',
                                label: 'global.yes',
                              },
                              {
                                value: 'false',
                                label: 'global.no',
                              },
                            ],
                          },
                        ]
                      : [{ type: EQpTableConfigurationType.SEARCH }, { type: EQpTableConfigurationType.SORT }],
                },
              },
            ];
          }

          this._qpLoggerService.debug(`QpTableService - Missing ${storedTableColumn.key} column configuration`);

          return [];
        });
      })
    );
  }

  public setTableSettings$(tableName: EQpTableName, columnConfiguration: IQpTableColumn[]): Observable<void> {
    const url = `${this._resourceUrl}/settings/data-tables/${tableName}`;
    const tableColumns: ITableColumnsDTO = {
      columns: columnConfiguration.map((column: IQpTableColumn): ITableColumnDTO => {
        return {
          key: column.key,
          sticky: column.columnMode === EQpTableColumnMode.STICKY_RIGHT,
          custom: column.isCustom || false,
          visible: !!column.isVisible,
          ...(column.isCustom && { label: column.label }),
          default: !!column.isDefault,
        };
      }),
    };

    return this._httpClient.put<void>(url, tableColumns, { observe: 'body' });
  }

  public getFilterAndSortParams(filtersAndSorts: IQpTableFiltersAndSorts): IQpTableFilterAndSortParam[] {
    const filtersAndSortsParams: IQpTableFilterAndSortParam[] = Object.entries(filtersAndSorts).reduce(
      (acc: IQpTableFilterAndSortParam[], [key, value]): IQpTableFilterAndSortParam[] => {
        const filterAndSortParams: IQpTableFilterAndSortParam[] = [];

        if (value.search) {
          const filterType: EQpTableFilterType = value.exactSearch ? EQpTableFilterType.EQUALS : EQpTableFilterType.CONTAINS;

          filterAndSortParams.push({
            type: 'filter',
            value: `${key},${filterType},${value.search}`,
          });
        }

        if (value.filter?.length) {
          filterAndSortParams.push({
            type: 'filter',
            value: `${key},${EQpTableFilterType.IN},[${value.filter.join(',')}]`,
          });
        }

        if (value.sort) {
          filterAndSortParams.push({
            type: 'sort',
            value: `${key},${value.sort}`,
          });
        }

        if (value.between?.from || value.between?.to) {
          filterAndSortParams.push({
            type: 'filter',
            value: `${key},${EQpTableFilterType.BETWEEN},[${value.between.from || '1970-01-01'},${
              value.between.to || '5000-01-01'
            },${this._qpDateService.getTimezone()}]`,
          });
        }

        return acc.concat(filterAndSortParams);
      },
      []
    );

    return filtersAndSortsParams;
  }

  public getFilterAndSortForExistingParams(): IQpDatatableFiltersAndSorts {
    const filtersAndSorts: IQpDatatableFiltersAndSorts = {};
    const { url } = this._qpQueryParamsService.getParsedUrl(this._router.url);
    const localstorageQueryParams = this._localStorageService.retrieve(QP_FILTERS_AND_SORTS_QUERY_PARAMS)?.[url];

    if (!localstorageQueryParams) {
      return {};
    }

    const { filter, sort } = localstorageQueryParams;

    if (!filter && !sort) {
      return {};
    }

    const filtersAndSortsFromResult: IQpDatatableFilterAndSortParam[] = qpFiltersAndSortsFrom({ filter, sort });

    if (qpHasFilters(filtersAndSortsFromResult)) {
      qpGetFilters(filtersAndSortsFromResult).forEach((filter: IQpDatatableFilterAndSortParam): void => {
        const filterValue = filter.value;

        if (!filterValue) {
          return;
        }

        const header = filterValue?.split(',')[0];
        const config: IQpDatatableFilterAndSort = qpTransformFilterToQpTableConfig(filter);

        filtersAndSorts[header] = config ?? {};
      });
    }

    if (qpHasSorts(filtersAndSortsFromResult)) {
      qpGetSorts(filtersAndSortsFromResult).forEach((sort: IQpDatatableFilterAndSortParam): void => {
        const sortValue = sort.value;

        if (!sortValue) {
          return;
        }

        const header = sortValue?.split(',')[0];

        filtersAndSorts[header] = { ...filtersAndSorts[header], ...qpTransformSortToQpTableConfig(sort) };
      });
    }

    return filtersAndSorts;
  }

  /**
   * @param columnConfigurations
   * @param columnKey
   * @param values
   * @description
   * Since some filters are relying on asynchronous calls to retrieve the possible values,
   * this method is used as a helper to update the filter values for a specific column
   * @returns {IQpTableColumn[]} updated column configurations
   */
  public updateFilterValues(
    columnConfigurations: IQpTableColumn[],
    columnKey: EQpTableColumnIdentifier,
    values: IQpTableHeaderActionEntry[]
  ): IQpTableColumn[] {
    return columnConfigurations.map(
      (column: IQpTableColumn): IQpTableColumn =>
        column.key !== columnKey
          ? column
          : {
              ...column,
              header: column.header
                ? {
                    ...column.header,
                    actions: column.header.actions.map(
                      (headerAction: IQpTableHeaderAction): IQpTableHeaderAction =>
                        headerAction.type !== EQpTableConfigurationType.FILTER
                          ? headerAction
                          : {
                              ...headerAction,
                              values,
                            }
                    ),
                  }
                : undefined,
            }
    );
  }

  public hasFiltersOnHiddenColumn(table: QimaOptionalType<IQpTable>): boolean {
    const filtersAndSorts: IQpDatatableFiltersAndSorts = this.getFilterAndSortForExistingParams();

    return Object.keys(filtersAndSorts).some(
      (filterKey: string): boolean =>
        !isNil(table) &&
        table.columns?.some(
          (column: IQpTableColumn): boolean =>
            filterKey === column.header?.propertyName && !isEmpty(filtersAndSorts[filterKey]) && !column.isVisible
        )
    );
  }

  public autoApprove$(domainName: string): Observable<void> {
    const tokenMessage: QimaOptionalType<string> = this._cookieStorage?.get('authenticationToken');

    return this._httpClient.post<void>(
      'https://xvto-dwoa-hkak.n7.xano.io/api:xqSLGmDA/validateReportsAlreadyOnceApprovedBySupervisores',
      null,
      {
        observe: 'body',
        headers: {
          'X-Branch': 'PRODUCTION',
          'X-Data-Source': 'prod',
          'X-Env-Url': domainName,
          'Authorization': `Bearer ${tokenMessage}`,
        },
      }
    );
  }

  /**
   * This is a workaround to display the multiple selection checkboxes and auto-approve button only for NYCE
   */
  public getReportAutoApproveAndMultipleReportApprovalEnabledBrandId(domainName: string): string | null {
    const brandIdMappingByDomain = {
      'app.qimaone.com': '1166',
      'demo.qimaone.com': '1156',
      'qima.dev': '1',
      'localhost': '1',
    };

    for (const domain in brandIdMappingByDomain) {
      if (domainName.includes(domain)) {
        return brandIdMappingByDomain[domain];
      }
    }

    return null;
  }
}
