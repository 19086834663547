<div class="empty-table">
  <img
    [alt]="'global.datatable.empty-row.message' | translate"
    [ngClass]="imageSize"
    [src]="image"
    class="builder__preview__emptycontent__image"
  />
  <p class="empty-table__message">
    {{ message | translate }}
  </p>
  <p class="empty-table__sub-message" *ngIf="subMessage">
    {{ subMessage | translate }}
  </p>
</div>
