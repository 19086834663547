import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';

/**
 * @param data
 */
export function qpIsFilterAndSort<T>(data: T | IQpDatatableFilterAndSortParam): data is IQpDatatableFilterAndSortParam {
  const cast: IQpDatatableFilterAndSortParam = data as IQpDatatableFilterAndSortParam;

  return cast.type !== undefined && cast.value !== undefined;
}
