import {
  IQpDatatableFiltersAndSorts,
  IQpDatatableFilterAndSortParam,
  IQpDatatableFilterAndSort,
} from '@library/components/qp-datatable/qp-datatable.models';
import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { qpFiltersAndSortsFrom } from '@library/functions/filters/qp-filters-and-sorts-from';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpHasFilters } from '@library/functions/filters/qp-has-filters';
import { qpGetSorts } from '@library/functions/sorts/qp-get-sorts';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { qpTransformFilterToQpTableConfig } from '@library/functions/table/qp-transform-filter-to-qp-table-config';
import { qpTransformSortToQpTableConfig } from '@library/functions/table/qp-transform-sort-to-qp-table-config';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class QpDatatableService {
  public constructor(
    private readonly _qpQueryParamsService: QpQueryParamsService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router
  ) {}

  public getFilterAndSortForExistingParams(): IQpDatatableFiltersAndSorts {
    const filtersAndSorts: IQpDatatableFiltersAndSorts = {};
    const { url } = this._qpQueryParamsService.getParsedUrl(this._router.url);
    const localstorageQueryParams = this._localStorageService.retrieve(QP_FILTERS_AND_SORTS_QUERY_PARAMS)?.[url];

    if (!localstorageQueryParams) {
      return {};
    }

    const { filter, sort } = localstorageQueryParams;

    if (!filter && !sort) {
      return {};
    }

    const filtersAndSortsFromResult: IQpDatatableFilterAndSortParam[] = qpFiltersAndSortsFrom({ filter, sort });

    if (qpHasFilters(filtersAndSortsFromResult)) {
      qpGetFilters(filtersAndSortsFromResult).forEach((filter: IQpDatatableFilterAndSortParam): void => {
        const filterValue = filter.value;

        if (!filterValue) {
          return;
        }

        const header = filterValue?.split(',')[0];
        const config: IQpDatatableFilterAndSort = qpTransformFilterToQpTableConfig(filter);

        filtersAndSorts[header] = config ?? {};
      });
    }

    if (qpHasSorts(filtersAndSortsFromResult)) {
      qpGetSorts(filtersAndSortsFromResult).forEach((sort: IQpDatatableFilterAndSortParam): void => {
        const sortValue = sort.value;

        if (!sortValue) {
          return;
        }

        const header = sortValue?.split(',')[0];

        filtersAndSorts[header] = { ...filtersAndSorts[header], ...qpTransformSortToQpTableConfig(sort) };
      });
    }

    return filtersAndSorts;
  }
}
