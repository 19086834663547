import { QpQueryParamUrlType, QpQueryParamsType } from '@library/services/qp-query-params/qp-query-params.service.models';
import { Injectable } from '@angular/core';
import { stringifyUrl, ParsedQuery, parseUrl, ParsedUrl, ParseOptions } from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class QpQueryParamsService {
  /**
   * @description
   * Add some query params to a given url (override existing ones)
   * `null` and `undefined` params will be removed
   *
   * @param {Readonly<QpQueryParamUrlType>} url The url to update
   * @param {Readonly<QpQueryParamsType>} queryParams The query params to add to the url
   *
   * @returns {QpQueryParamUrlType} The url merged with the query params
   */
  public addQueryParams(url: Readonly<QpQueryParamUrlType>, queryParams: Readonly<QpQueryParamsType>): QpQueryParamUrlType {
    return stringifyUrl(
      {
        url,
        query: queryParams,
      },
      {
        skipNull: true,
      }
    );
  }

  public getQueryParams(url: Readonly<QpQueryParamUrlType>, options?: Partial<ParseOptions>): ParsedQuery<string | number | boolean> {
    return parseUrl(url, {
      parseBooleans: true,
      parseNumbers: true,
      ...options,
    }).query;
  }

  public getPathname(url: Readonly<QpQueryParamUrlType>): string {
    return parseUrl(url).url;
  }

  public getParsedUrl(url: Readonly<QpQueryParamUrlType>): ParsedUrl {
    return parseUrl(url);
  }
}
