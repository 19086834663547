import { IQpCheckboxStateChangeEvent } from '@library/components/qp-checkbox/qp-checkbox.models';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpDisabledDirective } from '@library/directives/qp-disabled/qp-disabled.directive';
import { QpHasContentDirective } from '@library/directives/qp-has-content/qp-has-content.directive';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'qp-checkbox',
  templateUrl: './qp-checkbox.component.html',
  styleUrls: ['./qp-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): typeof QpCheckboxComponent => QpCheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, QpIconComponent, ReactiveFormsModule, FormsModule, QpDisabledDirective, QpHasContentDirective],
})
export class QpCheckboxComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public readonly iconNames: typeof EQpIconName = EQpIconName;

  @Input()
  public qpDisabled: QimaOptionalType<boolean> = false;

  @Input()
  public shouldAnimateOnInit: boolean = true;

  @Input()
  public isPartiallyCheck: boolean = false;

  @Input()
  public get qpChecked(): boolean {
    return this._qpChecked;
  }

  public set qpChecked(isQpChecked: boolean) {
    this._qpChecked = isQpChecked;
    this._qpCheckedUpdatedCounter++;

    if (this._qpCheckedUpdatedCounter > 1) {
      this.isAnimated = true;
    }
  }

  public isAnimated: boolean = true;
  public hasCustomContent: boolean = false;

  @Output()
  public readonly checkedChange: EventEmitter<IQpCheckboxStateChangeEvent> = new EventEmitter<IQpCheckboxStateChangeEvent>();

  private _qpChecked = false;
  private _qpCheckedUpdatedCounter = 0;

  public constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _focusMonitor: FocusMonitor
  ) {}

  public ngOnInit(): void {
    this.isAnimated = this.shouldAnimateOnInit;
  }

  public onChange: (value: unknown) => void = (): null => null;
  public onTouched: () => unknown = (): null => null;

  public changeState($event: MouseEvent): void {
    /**
     * @description
     * Some features expect the click to go through the checkbox
     * So adding the stop propagation here is not good because too intrusive
     * In the case it is disabled it makes more sense to stop the propagation though
     */
    if (!this.qpDisabled) {
      this.qpChecked = !this.qpChecked;
      this.onChange(this.qpChecked);
      this.checkedChange.emit({
        isSelected: this.qpChecked,
        mouseEvent: $event,
      });
    } else {
      $event.stopPropagation();
    }
  }

  public writeValue(isChecked: boolean): void {
    this.qpChecked = isChecked;
  }

  public registerOnChange(fn: (_?: unknown) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (_?: unknown) => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.qpDisabled = isDisabled;
  }

  public onContentChange(hasContent: Readonly<boolean>): void {
    this.hasCustomContent = hasContent;
  }

  public ngOnDestroy(): void {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
