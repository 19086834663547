import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qp-no-result-table',
  templateUrl: './qp-no-result-table.component.html',
  styleUrls: ['./qp-no-result-table.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class QpNoResultTableComponent {
  @Input() public message = '';
}
