<div class="qp-datatable" *ngIf="!isLoading; else loadingTable">
  <ng-container *ngIf="isTitleNeeded">
    <div class="qp-datatable__title">
      <div class="qp-datatable__title-label">
        {{ title }}
      </div>
      <div class="qp-datatable__title-action">
        <qp-button [type]="buttonTypes.PRIMARY" [size]="buttonSizes.SMALL" (click)="onClickOfActionButton()">
          <qp-icon [name]="iconNames.IC_ADD"></qp-icon>
          <qp-button-label>{{ actionButtonLabel | translate }}</qp-button-label>
        </qp-button>
      </div>
    </div>
  </ng-container>
  <table class="qp-datatable__table" #rootTable>
    <caption>
      <!-- to keep for avoid the code smell error -->
    </caption>
    <tr class="datatable_header">
      <ng-container *ngFor="let header of headers; let index = index; trackBy: trackByHeader">
        <th
          [class.have-config]="
            columnsConfigurations &&
            columnsConfigurations[index] &&
            (filtersAndSorts | qpHasFilterAndSort: columnsConfigurations[index].property)
          "
          [class.is-submenu-active]="isSubmenuActive"
          [class.stickyHeader--active]="columnOnOverlay === index"
          [class.stickyHeader]="isStickyHeader"
          [id]="header"
          class="datatable_header_column"
          data-test-id="datatable-header-column"
        >
          <span
            *ngIf="
              !columnsConfigurations || !columnsConfigurations[index] || columnsConfigurations[index].configurations?.length === 0;
              else headerWithOptions
            "
          >
            {{ header | translate }}
          </span>
          <ng-template #headerWithOptions>
            <qp-dropdown
              (onDropdownClose)="closeDropDown()"
              (onDropdownOpen)="onDropdownOpen(index)"
              [icon]="
                (filtersAndSorts | qpHasFilterAndSort: columnsConfigurations[index].property)
                  ? iconNames.IC_XS_FILTERS_APPLICATED
                  : iconNames.IC_XS_CHEVRON_DOWN_FILTERS
              "
              [label]="header | translate"
            >
              <!-- Weird ngIf here but on purpose -->
              <!-- It allow to built the content of the header only when the header needs to be display -->
              <!-- This will be better for the performances BUT also required for example to autofocus the search input -->
              <ng-container *ngIf="columnOnOverlay === index">
                <div
                  *ngFor="let configuration of columnsConfigurations[index].configurations; trackBy: trackByIndex"
                  class="configurations"
                >
                  <div *ngIf="configuration.type === datatableConfigurationTypes.SEARCH" class="configurations__searching">
                    <qp-icon class="configurations__searching-icon" [name]="iconNames.IC_SEARCH"></qp-icon>
                    <input
                      (ngModelChange)="search(columnsConfigurations[index].property, $event)"
                      [ngModel]="filtersAndSorts[columnsConfigurations[index].property]?.search"
                      [placeholder]="'global.datatable.config.search' | translate"
                      type="text"
                      qimaAutoFocus
                    />
                  </div>
                  <div *ngIf="configuration.type === datatableConfigurationTypes.SORT" class="configurations__sorting">
                    <qp-label>{{ 'global.datatable.config.sort' | translate }}</qp-label>
                    <ul class="configurations__sorting__list">
                      <li
                        (click)="sort(columnsConfigurations[index].property, 'ASC')"
                        [class.active]="filtersAndSorts[columnsConfigurations[index].property]?.sort === 'ASC'"
                        class="configurations__sorting__list__item"
                      >
                        <em nz-icon nzType="ic-ascending-filters"></em>
                        {{ 'global.datatable.config.ascending' | translate }}
                      </li>
                      <li
                        (click)="sort(columnsConfigurations[index].property, 'DESC')"
                        [class.active]="filtersAndSorts[columnsConfigurations[index].property]?.sort === 'DESC'"
                        class="configurations__sorting__list__item"
                      >
                        <em nz-icon nzType="ic-descending-filters"></em>
                        {{ 'global.datatable.config.descending' | translate }}
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="configuration.type === datatableConfigurationTypes.FILTER" class="configurations__filtering">
                    <ul class="configurations__filtering__list">
                      <li
                        (click)="filter(columnsConfigurations[index].property, value.value)"
                        *ngFor="let value of configuration.values; trackBy: trackByIndex"
                        class="configurations__filtering__list__item"
                      >
                        <qp-checkbox [qpChecked]="filtersAndSorts[columnsConfigurations[index].property]?.filter?.includes(value.value)">
                          <qp-label class="caption" type="informative">
                            {{ value.label }}
                          </qp-label>
                        </qp-checkbox>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="configuration.type === datatableConfigurationTypes.BETWEEN" class="configurations__date-range">
                    <qp-label>{{ 'global.datatable.config.date-range.label' | translate }}</qp-label>
                    <label [for]="'from-' + index" class="configurations__date-range__item">
                      {{ 'global.datatable.config.date-range.from' | translate }}
                      <div class="input-date">
                        <input
                          (ngModelChange)="between(columnsConfigurations[index].property, $event, 'from')"
                          [id]="'from-' + index"
                          [ngModel]="filtersAndSorts[columnsConfigurations[index].property]?.between?.from"
                          type="date"
                        />
                        <div class="input-date__icon">
                          <em nz-icon nzType="ic-date"></em>
                        </div>
                      </div>
                    </label>
                    <label [for]="'to-' + index" class="configurations__date-range__item">
                      {{ 'global.datatable.config.date-range.to' | translate }}
                      <div class="input-date">
                        <input
                          (ngModelChange)="between(columnsConfigurations[index].property, $event, 'to')"
                          [id]="'to-' + index"
                          [ngModel]="filtersAndSorts[columnsConfigurations[index].property]?.between?.to"
                          type="date"
                        />
                        <div class="input-date__icon">
                          <em nz-icon nzType="ic-date"></em>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
            </qp-dropdown>
          </ng-template>
        </th>
      </ng-container>
      <th
        *ngIf="hasActions"
        [class.is-submenu-active]="isSubmenuActive"
        [class.stickyHeader]="isStickyHeader"
        class="actions-column"
        id="action"
      >
        <a (click)="clearAll()" *ngIf="filtersAndSorts | qpHasFiltersAndSorts" class="actions-column__clear-all">
          {{ 'global.datatable.config.clearall' | translate }}
        </a>
      </th>
    </tr>
    <ng-container *ngIf="!isEmpty">
      <ng-content></ng-content>
    </ng-container>
  </table>
</div>

<ng-template #loadingTable>
  <qp-datatable-fake></qp-datatable-fake>
</ng-template>

<qp-empty-table *ngIf="isEmpty && !isLoading"></qp-empty-table>
