import { EQimaTooltipStyle } from '../../../directives/abstract-tooltip/enums/qima-tooltip-style.enum';
import { EQimaPosition } from '../../../models/enums/qima-position.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaTooltipComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaTooltipConfig>}
 */
export const QIMA_TOOLTIP_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the the tooltip component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaTooltipModule#forRoot} values
     */
    return {
      isVisible: true,
      hasArrow: false,
      position: EQimaPosition.TOP,
      style: EQimaTooltipStyle.DARK
    };
  }
});
