import { HttpParams } from '@angular/common/http';

/**
 * @param req
 */
export function createRequestOption(req?: Record<string, unknown>): HttpParams {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach((key): void => {
      if (key !== 'sort') {
        options = options.set(key, req[key] as string | number | boolean);
      }
    });

    if (req.sort) {
      (req.sort as string[]).forEach((val: string): void => {
        options = options.append('sort', val);
      });
    }
  }

  return options;
}
