import { QpContentObserverDirective } from '@library/directives/qp-content-observer/qp-content-observer.directive';
import { Directive, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { isEmpty } from 'lodash/index';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[qpHasContent]',
  standalone: true,
})
export class QpHasContentDirective extends QpContentObserverDirective implements OnDestroy {
  @Output('qpHasContentChange')
  public readonly hasContentChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _contentChangeSubscription!: Subscription;

  public constructor(private readonly _elementRef: ElementRef) {
    super(_elementRef);

    this._watchContentChange();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this._contentChangeSubscription.unsubscribe();
  }

  private _watchContentChange(): void {
    this._contentChangeSubscription = this.change.subscribe({
      next: (mutation: Readonly<MutationRecord>): void => {
        this._onContentChange(mutation);
      },
    });
  }

  private _onContentChange(mutation: Readonly<MutationRecord>): void {
    if (mutation.type === 'childList') {
      this.hasContentChange.emit(this._hasChildNodes());
    }
  }

  private _hasChildNodes(): boolean {
    return !isEmpty(this._getChildNodes());
  }

  private _getChildNodes(): ChildNode[] {
    const childNodes: ChildNode[] = Array.from(this._elementRef.nativeElement.childNodes);

    return childNodes.filter((childNode: Readonly<ChildNode>): boolean => {
      // Ignore the comments in the DOM
      return childNode.nodeType !== Node.COMMENT_NODE;
    });
  }
}
