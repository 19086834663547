import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash/index';

/**
 * @param pageQuery
 * @param filtersAndSorts
 */
export function qpAsHttpParams(pageQuery: IQpPageQuery, filtersAndSorts: IQpDatatableFilterAndSortParam[] = []): HttpParams {
  let params: HttpParams = new HttpParams();

  Object.keys(pageQuery).forEach((key: string): void => {
    if (isNil(pageQuery[key])) {
      return;
    }

    params = params.set(key, String(pageQuery[key]));
  });

  filtersAndSorts.forEach((item): void => {
    params = params.append(item.type, item.value);
  });

  return params;
}
