/**
 * @description Assert that the condition is true or throw an error
 * @param {unknown} condition The condition to check
 * @param {string} message The message to throw if the condition is false
 * 🔗 https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 */
export function qpAssert(condition: unknown, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
