import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[qpTrim]',
  standalone: true,
})
export class QpTrimDirective {
  @HostListener('blur', ['$event.target', '$event.target.value'])
  public onBlur(el: HTMLInputElement, value: string): boolean {
    if ('function' === typeof value.trim && value.trim() !== value) {
      el.value = value.trim();
      el.dispatchEvent(new Event('input'));
      el.dispatchEvent(new Event('blur'));
    }

    return true;
  }
}
