import { EQimaPosition } from '../../../models/enums/qima-position.enum';
import { EQimaTooltipStrategy } from '../../abstract-tooltip/enums/qima-tooltip-strategy.enum';
import { EQimaTooltipStyle } from '../../abstract-tooltip/enums/qima-tooltip-style.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaAutoTooltipDirective} to define the default values of its inputs
 * @type {InjectionToken<IQimaAutoTooltipDirectiveConfig>}
 */
export const QIMA_AUTO_TOOLTIP_DIRECTIVE_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the auto tooltip directive', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaAutoTooltipDirectiveModule#forRoot} values
     */
    return {
      hasArrow: false,
      isVisible: true,
      position: EQimaPosition.TOP,
      strategy: EQimaTooltipStrategy.ON_HOVER,
      style: EQimaTooltipStyle.DARK
    };
  }
});
