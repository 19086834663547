import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

@Directive({
  selector: '[ngForQpTrackByIndex]',
  standalone: true,
})
export class QpTrackByIndexDirective<T> {
  public constructor(@Host() private readonly _ngFor: NgForOf<T>) {
    this._ngFor.ngForTrackBy = (index: Readonly<number>): unknown => index;
  }
}
