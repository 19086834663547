import { IQpDatatableFilterAndSortParam, IQpDatatableFilterAndSort } from '@library/components/qp-datatable/qp-datatable.models';

/**
 * @param {IQpDatatableFilterAndSortParam} sort The sort to transform
 */
export function qpTransformSortToQpTableConfig(sort: IQpDatatableFilterAndSortParam): IQpDatatableFilterAndSort {
  const { type, value: sortValue } = sort;
  const value = sortValue?.split(',')[1];

  return {
    [type]: value,
  };
}
