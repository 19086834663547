<div class="qp-text-container">
  <div (click)="togglePreview()" *ngIf="isInPreview" [ngClass]="{ 'no-padding': !hasPadding }" class="qp-text-container__preview">
    <span *ngIf="type === 'date'; else simpleValueBlock">{{ value | qpDate }}</span>
    <ng-template #simpleValueBlock>
      <span>{{ value }}</span>
    </ng-template>
    <em class="qp-text-container__preview__icon" nz-icon nzType="ic-edit-field"></em>
  </div>
  <div *ngIf="!isInPreview" [ngClass]="{ hasSuffix: !!suffixIcon, hasPrefix: !!prefixIcon }" class="qp-text-container__input">
    <em *ngIf="prefixIcon" [nzType]="prefixIcon" class="qp-text-container__input__prefix" nz-icon></em>
    <input
      #input
      (click)="$event.stopPropagation()"
      (focusout)="sentFocusOut($event)"
      (keypress)="keyPress($event)"
      (ngModelChange)="handleChange($event)"
      [attr.maxlength]="maxlength"
      [disabled]="isDisabled"
      [ngModel]="value"
      [placeholder]="placeholder"
      [readonly]="isReadOnly"
      [type]="type"
      [class.no-padding]="!hasPadding"
      [class.has-preview]="hasPreview"
      [qpAutocomplete]="qpTextAutocomplete"
      qpTrim
    />
    <em *ngIf="suffixIcon" [nzType]="suffixIcon" class="qp-text-container__input__suffix" nz-icon></em>
    <div
      (click)="handleDelete(); $event.stopPropagation()"
      *ngIf="!isEmpty && value && !isDisabled && !isReadOnly && !suffixIcon && !hasPreview"
      class="qp-text-clear"
    >
      <em nz-icon nzType="ic-close-12"></em>
    </div>
  </div>

  <qp-label *ngIf="qpError && qpErrorMessage" [error]="true" type="informative">{{ qpErrorMessage | translate }}</qp-label>
  <ng-content *ngIf="qpError"></ng-content>
</div>
