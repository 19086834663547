// @ts-strict-ignore
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpAutocompleteDirective } from '@library/directives/qp-autocomplete/qp-autocomplete.directive';
import { EQpAutocompleteValue } from '@library/directives/qp-autocomplete/qp-autocomplete.models';
import { QpTrimDirective } from '@library/directives/qp-trim/qp-trim.directive';
import { QpDatePipe } from '@library/pipes/qp-date/qp-date.pipe';
import { NgIf, NgClass } from '@angular/common';
import { Component, OnInit, Input, forwardRef, EventEmitter, Output, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { NzIconModule } from 'ng-zorro-antd/icon';

/**
 * @deprecated
 * Use instead {@link QpInputTextComponent}
 * @description
 * This component is extended by:
 * - {@link QpInputPasswordComponent}
 * - {@link QpTextFailComponent}
 *
 * So make sure your modification does not have unexpected side effects on the components mentioned above
 */
@Component({
  selector: 'qp-text',
  templateUrl: './qp-text.component.html',
  styleUrls: ['./qp-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): typeof QpTextComponent => QpTextComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NzIconModule,
    QpTrimDirective,
    ReactiveFormsModule,
    FormsModule,
    QpAutocompleteDirective,
    QpLabelComponent,
    TranslateModule,
    QpDatePipe,
  ],
})
export class QpTextComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() public isDisabled = false;
  @Input() public maxlength = 50;
  @Input() public isReadOnly = false;
  @Input() public placeholder = '';
  @Input() public prefixIcon = '';
  @Input() public suffixIcon = '';
  @Input() public capitalize = false;
  @Input() public hasPreview = false;
  @Input() public hasPadding = true;
  @Input() public hasAutoFocus = false;
  @Input() public hasAutoSelect = false;
  @Input() public value = '';
  @Input() public qpError = false;
  @Input() public qpErrorMessage = '';
  @Input() public type = '';
  @Input() public qpTextAutocomplete: QimaOptionalType<EQpAutocompleteValue>;

  @Output() public onFocusOut: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('input') public input: ElementRef;
  public isEmpty: boolean;
  public isInPreview = false;

  public ngOnInit(): void {
    this.placeholder = this.placeholder || '';
    this.type = this.type ? this.type : 'text';

    if (this.capitalize && this.value) {
      this.value = this._capitalizeValue(this.value);
    }

    if (this.hasPreview) {
      this.isInPreview = true;
    }
  }

  public ngAfterViewInit(): void {
    if (this.hasAutoSelect && !this.isInPreview) {
      setTimeout((): void => {
        this.input.nativeElement.select();
      }, 500);
    } else if (this.hasAutoFocus && !this.isInPreview) {
      this.input.nativeElement.focus();
    }
  }

  public togglePreview(): void {
    this.isInPreview = false;
    setTimeout((): void => {
      if (this.hasAutoSelect) {
        this.input.nativeElement.select();
      } else if (this.hasAutoFocus) {
        this.input.nativeElement.focus();
      }
    });
  }

  public registerOnChange(fn: (change: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  public sentFocusOut(event): void {
    this._onTouch();
    this.onFocusOut.emit(event);

    if (this.hasPreview) {
      this.isInPreview = true;
    }
  }

  public handleChange(val: string): void {
    this.isEmpty = val === undefined || val === null || val === '';

    if (this.isEmpty) {
      this.value = null;
      this._onChange(null);
    } else {
      if (this.capitalize) {
        this.value = this._capitalizeValue(val);
      } else {
        this.value = val;
      }

      this._onChange(val);
    }
  }

  public handleDelete(): void {
    this.value = null;
    this.handleChange(this.value);
  }

  /**
   * Angular will call this method in one of the following cases:
   * 1. When you instantiate a new FormControl with the disabled property set to true. FormControl({value: '', disabled: true})
   * 2. When you call control.disable() or when you call control.enable() after your already called control.disable() at least once.
   * In our case, we need to add a disabled class to our input to simulate a disabled textarea.
   *
   * @param isDisabled
   */
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Write a new value to the element. Angular will call this method with the value in one of the following cases:
   * 1. When you instantiate a new FormControl .
   * 2. When you call this.control.patchValue/setValue(value)
   * In our case, we need to set the textContent property of our input to this value.
   *
   * @param obj
   */
  public writeValue(obj: string): void {
    this.isEmpty = obj === undefined || obj === null || obj === '';
    this.value = obj;
  }

  /**
   * Prevents from writing more characters than the maxLength
   * NB: this is only valid for keyboard input (you can still copy-paste invalid-sized text)
   *
   * @param $event
   */
  public keyPress($event): boolean {
    if ($event.key === 'Enter') {
      this.sentFocusOut($event);
    }

    return $event.target.value.length < this.maxlength;
  }

  private _capitalizeValue(value: string): string {
    return value.replace(/(^|[ -])(\w)/g, (s): string => s.toUpperCase());
  }

  private _onTouch: () => void = (): void => {
    return;
  };

  private _onChange: (change: string) => void = (): void => {
    return;
  };
}
