import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam, IQpDatatableFiltersAndSorts } from '@library/components/qp-datatable/qp-datatable.models';
import { EQpTableName, IQpTable, IQpTableColumn } from '@library/components/qp-table/qp-table.models';
import { QpTableService } from '@library/components/qp-table/services/qp-table.service';
import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { QP_ITEMS_PER_PAGE, QP_ITEMS_PER_PAGE_OPTIONS } from '@library/constants/items-per-page/qp-items-per-page';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { qpFiltersAndSortsFrom } from '@library/functions/filters/qp-filters-and-sorts-from';
import { qpParseInt } from '@library/functions/math/qp-parse-int';
import { qpAsParams } from '@library/functions/qp-as-params/qp-as-params';
import { qpRouterParamMapToParams } from '@library/functions/router/qp-router-param-map-to-params';
import { IQpConsultationPage, QpConsultationPage } from '@library/models/qp-consultation-page.models';
import { IQpLocalStorageFiltersAndSortsQueryParams } from '@library/models/qp-local-storage-filters-and-sorts-query-params.models';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { ChangeDetectorRef, inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isNil } from 'lodash/index';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
export abstract class QpPageList<T> {
  protected static readonly _defaultPageQuery: Readonly<IQpPageQuery> = {
    page: 1,
    size: QP_ITEMS_PER_PAGE,
  };

  private static _parseQueryParams(params: Readonly<ParamMap>): IQpPageQuery {
    let page: number = qpParseInt(params.get('page') ?? this._defaultPageQuery.page.toString());
    let size: number = qpParseInt(params.get('size') ?? this._defaultPageQuery.size.toString());

    if (!qpIsFiniteNumber(page)) {
      page = this._defaultPageQuery.page;
    }

    if (!qpIsFiniteNumber(size)) {
      size = this._defaultPageQuery.size;
    }

    return {
      ...qpRouterParamMapToParams(params),
      page,
      size,
    };
  }

  public abstract tableConfiguration: IQpTable;
  public readonly qpTableService: QpTableService = inject(QpTableService);
  public readonly itemsPerPageOptions: number[] = QP_ITEMS_PER_PAGE_OPTIONS;
  public readonly isCustomizePanelOpen$: BehaviorSubject<boolean> = this.qpTableService.isCustomizePanelOpen$;
  public filtersAndSortsParams: IQpDatatableFilterAndSortParam[] = [];
  public filtersAndSortsOnInit: IQpDatatableFiltersAndSorts = {};
  public page: IQpConsultationPage<T> | undefined = undefined;
  public isLoading: boolean = true;
  public isFirstTimeLoading: boolean = true;
  public isLoadingTableConfiguration: boolean = true;
  protected _pageParams: Readonly<IQpPageQuery> = QpPageList._defaultPageQuery;
  protected readonly _router: Router = inject(Router);
  protected readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly _localStorageService: LocalStorageService = inject(LocalStorageService);
  protected readonly _qpQueryParamsService: QpQueryParamsService = inject(QpQueryParamsService);

  public applyFiltersAndSorts(filtersAndSortsParams: IQpDatatableFilterAndSortParam[]): Promise<boolean> {
    const queryParams: Params = qpAsParams(
      {
        page: 1,
        size: this.page?.pageSize ?? QP_ITEMS_PER_PAGE,
      },
      filtersAndSortsParams
    );

    return this._updateUrlQueryParams(queryParams);
  }

  public trackByIndex(index: Readonly<number>): number {
    return index;
  }

  public setNewColumnConfiguration(newConfiguration: IQpTableColumn[]): void {
    this.tableConfiguration = {
      ...this.tableConfiguration,
      columns: newConfiguration,
    };

    this._removeFiltersWhenAnyActiveFilterColumnIsHidden();
  }

  public openCustomizePanel(): void {
    this.isCustomizePanelOpen$.next(true);
  }

  public loadTableConfiguration(tableName: EQpTableName): void {
    this.qpTableService
      .getTableSettings$(tableName, this.tableConfiguration.columns)
      .pipe(
        untilDestroyed(this),
        finalize((): void => {
          this.isLoadingTableConfiguration = false;
        })
      )
      .subscribe((tableColumnConfigurations: IQpTableColumn[]): void => {
        this.tableConfiguration = {
          ...this.tableConfiguration,
          columns: tableColumnConfigurations,
        };
        this._removeFiltersWhenAnyActiveFilterColumnIsHidden();
        this._changeDetectorRef.detectChanges();
      });
  }

  /**
   * @description
   * Watch the query params change
   *
   * Update the current page based on the query param "page"
   * Update the page size based on the query param "size"
   * Update the filter and sort params based on the query params
   *
   * Fetch the API data for this page
   * @returns {Observable<IQpConsultationPage>} The data associated to the page list
   */
  protected _watchQueryParamsChange$(): Observable<IQpConsultationPage<T>> {
    return this._activatedRoute.queryParamMap.pipe(
      map((params: Readonly<ParamMap>): IQpPageQuery => QpPageList._parseQueryParams(params)),
      distinctUntilChanged((oldParams: Readonly<IQpPageQuery>, newParams: Readonly<IQpPageQuery>): boolean =>
        isEqual(oldParams, newParams)
      ),
      tap((params: Readonly<IQpPageQuery>): void => {
        // The page doesn't exist on the first load
        // We create it and update the query params to display in the browser the pagination data
        this._pageParams = params;
        this.isLoading = true;

        if (!this.page) {
          this._setPage(
            QpConsultationPage.create({
              totalItems: 0,
              items: [],
              totalPageCount: 0,
              page: params.page,
              pageSize: params.size,
            })
          );
          qpAssert(!isNil(this.page), 'The page should be defined');
          void this._updateUrlQueryParams(params, false);
        } else {
          this.page.page = params.page;
          this.page.pageSize = params.size;
        }

        this.filtersAndSortsParams = qpFiltersAndSortsFrom(params);
        this._saveParamsToLocalStorage(this.page);
      }),
      /**
       * @description
       * FYI {@link switchMap} to cancel ongoing HTTP calls when the query change
       */
      switchMap(
        (params: Readonly<IQpPageQuery>): Observable<IQpConsultationPage<T>> =>
          this._loadPage$(params).pipe(
            catchError((e): Observable<never> => {
              console.error(e);

              this._setPage(
                QpConsultationPage.create<T>({
                  items: [],
                  page: (this.page?.page ?? 0) + 1,
                  pageSize: this.page?.pageSize ?? QP_ITEMS_PER_PAGE,
                  totalItems: 0,
                  totalPageCount: 0,
                })
              );

              return EMPTY;
            })
          )
      )
    );
  }

  protected _updateUrlQueryParams(queryParams: Readonly<Params>, shouldReplaceUrl: Readonly<boolean> = true): Promise<boolean> {
    return this._router.navigate([], {
      queryParams,
      relativeTo: this._activatedRoute,
      replaceUrl: shouldReplaceUrl,
    });
  }

  private _removeFiltersWhenAnyActiveFilterColumnIsHidden(): void {
    // When we have at least one filter applied on a column not displayed anymore, we remove all filters
    if (this.qpTableService.hasFiltersOnHiddenColumn(this.tableConfiguration)) {
      void this.applyFiltersAndSorts([]);
    }
  }

  private _saveParamsToLocalStorage(page: IQpConsultationPage<T>): void {
    const { url, query } = this._qpQueryParamsService.getParsedUrl(this._router.url);
    const storedFiltersAndSorts = this._localStorageService.retrieve(QP_FILTERS_AND_SORTS_QUERY_PARAMS);
    const filtersAndSortsQueryParams: IQpLocalStorageFiltersAndSortsQueryParams = {
      ...storedFiltersAndSorts,
      [url]: { ...query, page: page.page.toString(), size: page.pageSize.toString() },
    };

    this._localStorageService.store(QP_FILTERS_AND_SORTS_QUERY_PARAMS, filtersAndSortsQueryParams);
    this.filtersAndSortsOnInit = this.qpTableService.getFilterAndSortForExistingParams();
  }

  protected abstract _setPage(page: IQpConsultationPage<T>): void;

  protected abstract _loadPage$(params: Readonly<IQpPageQuery>): Observable<IQpConsultationPage<T>>;
}
