// @ts-strict-ignore
import { QpButtonLabelComponent } from '../qp-button/components/qp-button-label/qp-button-label.component';
import { QpButtonComponent } from '../qp-button/qp-button.component';
import { QpCheckboxComponent } from '../qp-checkbox/qp-checkbox.component';
import { QpDatatableFakeComponent } from '../qp-datatable-fake/qp-datatable-fake.component';
import { QpDropdownComponent } from '../qp-dropdown/qp-dropdown.component';
import { QpEmptyTableComponent } from '../qp-empty-table/qp-empty-table.component';
import { QpIconComponent } from '../qp-icon/qp-icon.component';
import { QpLabelComponent } from '../qp-label/qp-label.component';
import { EQpButtonSize, EQpButtonType } from '@library/components/qp-button/qp-button.models';
import {
  IQpDatatableFiltersAndSorts,
  IQpDatatableFilterAndSortParam,
  EQpDatatableConfigurationType,
  IQpDatatableColumnConfigurations,
} from '@library/components/qp-datatable/qp-datatable.models';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpHasFilterAndSortTablePipe } from '@library/pipes/qp-has-filter-and-sort-table/qp-has-filter-and-sort-table.pipe';
import { QpHasFiltersAndSortsTablePipe } from '@library/pipes/qp-has-filters-and-sorts-table/qp-has-filters-and-sorts-table.pipe';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { NgIf, NgFor } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType, QimaAutoFocusDirectiveModule } from '@qima/ngx-qima';
import { isNil, has, isEqual } from 'lodash/index';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'qp-datatable',
  templateUrl: './qp-datatable.component.html',
  styleUrls: ['./qp-datatable.component.scss', './qp-datatable.responsive.scss', './qp-datatable.multiline.scss'],
  standalone: true,
  imports: [
    NgIf,
    QpButtonComponent,
    QpIconComponent,
    QpButtonLabelComponent,
    NgFor,
    QpDropdownComponent,
    ReactiveFormsModule,
    QimaAutoFocusDirectiveModule,
    FormsModule,
    QpLabelComponent,
    NzIconModule,
    QpCheckboxComponent,
    QpDatatableFakeComponent,
    QpEmptyTableComponent,
    TranslateModule,
    QpHasFilterAndSortTablePipe,
    QpHasFiltersAndSortsTablePipe,
  ],
})
export class QpDatatableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public headers: string[] = [];
  @Input() public columnsConfigurations: IQpDatatableColumnConfigurations[] = [];
  @Input() public template: QimaOptionalType<string> = undefined;
  @Input() public hasActions = true;
  @Input() public isStickyHeader = false;
  @Input() public multiLineStyle = '';
  @Input() public multiLine = false;
  @Input() public isSubmenuActive = false;
  @Input() public defaultFiltersAndSortsParams: IQpDatatableFiltersAndSorts = {};
  @Input() public isTitleNeeded = false;
  @Input() public title: QimaOptionalType<string> = '';
  @Input() public actionButtonLabel: QimaOptionalType<string> = '';
  @Output() public readonly actionButtonEvent: EventEmitter<void> = new EventEmitter<void>();

  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public readonly buttonSizes: typeof EQpButtonSize = EQpButtonSize;
  public readonly buttonTypes: typeof EQpButtonType = EQpButtonType;

  /**
   * @description
   * Display the loading state
   * @type {boolean}
   * @default false
   */
  @Input() public isLoading = false;

  /**
   * @description
   * Display the empty state
   *
   * The [loading state]{@link isLoading} will have precedence
   * @type {boolean}
   * @default false
   */
  @Input() public isEmpty = false;

  @Output() public readonly applyFiltersAndSorts: EventEmitter<IQpDatatableFilterAndSortParam[]> = new EventEmitter<
    IQpDatatableFilterAndSortParam[]
  >();

  public datatableConfigurationTypes: typeof EQpDatatableConfigurationType = EQpDatatableConfigurationType;
  public filtersAndSorts: IQpDatatableFiltersAndSorts = {};
  public columnOnOverlay: number = -1;

  @ViewChild('rootTable', { static: true })
  private readonly _elementRef: QimaOptionalType<ElementRef> = undefined;

  public constructor(
    private readonly _renderer: Renderer2,
    private readonly _qpDateService: QpDateService
  ) {}

  public ngOnInit(): void {
    this._initFiltersAndSorts();
    this._applyFiltersAndSorts();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      has(changes, 'columnsConfigurations') &&
      !isEqual(changes.columnsConfigurations.currentValue, changes.columnsConfigurations.previousValue)
    ) {
      this._initFiltersAndSorts();
      this._applyFiltersAndSorts();
    }
  }

  public ngAfterViewInit(): void {
    if (this._elementRef) {
      if (this.template) {
        this.template.split(' ').forEach((cssClass): void => this._renderer.addClass(this._elementRef.nativeElement, cssClass));
      }

      if (this.multiLine) {
        this._renderer.addClass(this._elementRef.nativeElement, 'multiline');
        this._renderer.addClass(this._elementRef.nativeElement, this.multiLineStyle);
      }
    }
  }

  public closeDropDown(): void {
    this.columnOnOverlay = -1;
    this._generateConfiguration();
  }

  public search(property: string, value: string): void {
    if (this.filtersAndSorts[property]) {
      if (!isNil(value)) {
        this.filtersAndSorts[property].search = value || undefined;
        this._generateConfiguration();

        return;
      }

      this.filtersAndSorts[property] = {};
    }
  }

  public sort(property: string, value: 'ASC' | 'DESC'): void {
    if (this.filtersAndSorts[property]) {
      if (this.filtersAndSorts[property].sort === value) {
        this.filtersAndSorts[property].sort = null;
      } else {
        this.filtersAndSorts[property].sort = value;
      }

      this._generateConfiguration();
    }
  }

  public filter(property: string, value: string): void {
    if (this.filtersAndSorts[property]) {
      if (!Array.isArray(this.filtersAndSorts[property].filter)) {
        this.filtersAndSorts[property].filter = [];
      }

      const index = this.filtersAndSorts[property].filter.indexOf(value);

      if (index === -1) {
        this.filtersAndSorts[property].filter.push(value);
      } else {
        this.filtersAndSorts[property].filter.splice(index, 1);
      }

      this._generateConfiguration();
    }
  }

  public between(property: string, value: string, key: 'from' | 'to'): void {
    if (this.filtersAndSorts[property]) {
      if (this.filtersAndSorts[property].between) {
        this.filtersAndSorts[property].between[key] = value;
      } else {
        this.filtersAndSorts[property].between = {
          [key]: value,
        };
      }

      this._generateConfiguration();
    }
  }

  public clearAll(): void {
    this._initFiltersAndSorts();
    this._generateConfiguration();
  }

  public trackByHeader(_index: Readonly<number>, header: Readonly<string>): string {
    return header;
  }

  public trackByIndex(index: Readonly<number>): number {
    return index;
  }

  public onClickOfActionButton(): void {
    this.actionButtonEvent.emit();
  }

  /**
   * @description
   * Called when the dropdown is opened
   * @param {Readonly<number>} index The index (starting from 1) related to the column
   */
  public onDropdownOpen(index: Readonly<number>): void {
    this.columnOnOverlay = index;
  }

  private _initFiltersAndSorts(): void {
    this.columnsConfigurations.forEach((column: IQpDatatableColumnConfigurations): void => {
      if (!column?.property) {
        return;
      }

      this.filtersAndSorts[column.property] = {};
    });
  }

  private _applyFiltersAndSorts(): void {
    if (Object.keys(this.defaultFiltersAndSortsParams).length) {
      Object.keys(this.defaultFiltersAndSortsParams).forEach((prop): void => {
        this.filtersAndSorts[prop] = this.defaultFiltersAndSortsParams[prop] ?? {};
      });
    }
  }

  private _generateConfiguration(): void {
    const filtersAndSortsParams: IQpDatatableFilterAndSortParam[] = Object.entries(this.filtersAndSorts).reduce(
      (acc: IQpDatatableFilterAndSortParam[], [key, value]): IQpDatatableFilterAndSortParam[] => {
        const filterAndSortParams: IQpDatatableFilterAndSortParam[] = [];

        if (value.search) {
          filterAndSortParams.push({
            type: 'filter',
            value: `${key},CONTAINS,${value.search}`,
          });
        }

        if (value.filter?.length) {
          filterAndSortParams.push({
            type: 'filter',
            value: `${key},IN,[${value.filter.join(',')}]`,
          });
        }

        if (value.sort) {
          filterAndSortParams.push({
            type: 'sort',
            value: `${key},${value.sort}`,
          });
        }

        if (value.between?.from || value.between?.to) {
          filterAndSortParams.push({
            type: 'filter',
            value: `${key},BETWEEN,[${value.between.from || '1970-01-01'},${
              value.between.to || '5000-01-01'
            },${this._qpDateService.getTimezone()}]`,
          });
        }

        return acc.concat(filterAndSortParams);
      },
      []
    );

    this.applyFiltersAndSorts.emit(filtersAndSortsParams);
  }
}
