import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { has, isNil, min } from 'lodash/index';

@Component({
  selector: 'qp-item-count',
  templateUrl: './qp-item-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class QpItemCountComponent implements OnChanges {
  /**
   * @description
   * Total number of items
   * @type {number}
   * @default undefined
   */
  @Input() public total: QimaOptionalType<number> = undefined;

  /**
   * @description
   * How many items per page
   * @type {number}
   * @default undefined
   */
  @Input() public itemsPerPage: QimaOptionalType<number> = undefined;

  /**
   * @description
   * Current page number
   * @type {number}
   * @default undefined
   */
  @Input() public page: QimaOptionalType<number> = undefined;

  public isValid = false;
  public itemMin: QimaOptionalType<number> = undefined;
  public itemMax: QimaOptionalType<number> = undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (has(changes, 'page') && changes.page.currentValue !== changes.page.previousValue) ||
      (has(changes, 'itemsPerPage') && changes.itemsPerPage.currentValue !== changes.itemsPerPage.previousValue) ||
      (has(changes, 'total') && changes.total.currentValue !== changes.total.previousValue)
    ) {
      const page = changes.page?.currentValue ?? this.page;
      const itemsPerPage = changes.itemsPerPage?.currentValue ?? this.itemsPerPage;
      const total = changes.total?.currentValue ?? this.total;

      this.isValid = !isNil(page) && !isNil(itemsPerPage) && !isNil(total);

      if (this.isValid) {
        this.itemMin = (page - 1) * itemsPerPage + 1;
        this.itemMax = min([page * itemsPerPage, total]);
      }
    }
  }
}
