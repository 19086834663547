import { ParamsBuilder } from '@library/classes/params/params-builder';
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { Params } from '@angular/router';

/**
 * @param query
 * @param filtersAndSorts
 */
export function qpAsParams(query: IQpPageQuery, filtersAndSorts: IQpDatatableFilterAndSortParam[] = []): Params {
  return new ParamsBuilder().with(query).with(filtersAndSorts);
}
