// @ts-strict-ignore
import { Component, OnInit, Input, HostBinding, OnChanges } from '@angular/core';

@Component({
  selector: 'qp-datacell',
  templateUrl: './qp-datacell.component.html',
  styleUrls: ['./qp-datacell.component.scss'],
  standalone: true,
})
export class QpDatacellComponent implements OnInit, OnChanges {
  @Input()
  public width = '';

  @HostBinding('style.width')
  public maxWidth: string;

  public ngOnInit(): void {
    this.maxWidth = this.width;
  }

  public ngOnChanges(): void {
    this.maxWidth = this.width;
  }
}
