const BASE_TEN: number = 10;

/**
 * @description
 * This method tries to parse a string into a number, with a default radix of 10
 * @param {string} text The input string to parse
 * @param {number} radix The base (10 for decimal, 16 for hexadecimal...)
 * @returns {number} The parsed value, as a number (or NaN)
 */
export function qpParseInt(text: string, radix: number = BASE_TEN): number {
  return parseInt(text, radix);
}
