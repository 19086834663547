import { EQpEmptyTableImageSize } from '@library/components/qp-empty-table/qp-empty-table.models';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qp-empty-table',
  templateUrl: './qp-empty-table.component.html',
  styleUrls: ['./qp-empty-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class QpEmptyTableComponent {
  @Input() public message = 'global.datatable.empty-row.message';
  @Input() public subMessage: string | undefined;
  @Input() public image = 'assets/pictures/img-empty.svg';
  @Input() public imageSize: EQpEmptyTableImageSize = EQpEmptyTableImageSize.MEDIUM;
}
