import { Directive, EventEmitter, Output, OnDestroy, ElementRef } from '@angular/core';

/**
 * @description
 * Observe the children on the node where this directive is applied
 * Emit the "childList" event
 */
@Directive({
  selector: '[qpContentObserver]',
})
export class QpContentObserverDirective implements OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output('qpContentObserverChange') public readonly change: EventEmitter<MutationRecord> = new EventEmitter<MutationRecord>();

  private readonly _changes: MutationObserver = new MutationObserver((mutations: MutationRecord[]): void => {
    mutations.forEach((mutation: Readonly<MutationRecord>): void => this.change.emit(mutation));
  });

  public constructor(elementRef: ElementRef) {
    this._changes.observe(elementRef.nativeElement, {
      childList: true,
    });
  }

  public ngOnDestroy(): void {
    this._changes.disconnect();
  }
}
