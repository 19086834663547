// @ts-strict-ignore
import { IQpDotMenuAction } from '@library/components/qp-dot-menu/qp-dot-menu.models';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'qp-dot-menu',
  templateUrl: './qp-dot-menu.component.html',
  styleUrls: ['./qp-dot-menu.component.scss'],
  standalone: true,
  imports: [NzPopoverModule, NzIconModule, NgFor, NgClass, NgIf, TranslateModule],
})
export class QpDotMenuComponent {
  @Input() public actions: IQpDotMenuAction[] = [];
  @Input() public rowData: Record<string, unknown> = undefined;
  @Input() public placement: 'top' | 'right' | 'bottom' | 'left' = 'left';
  public visible: boolean;

  public onEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  public actionClick(action: IQpDotMenuAction, event: MouseEvent): void {
    this.visible = false;
    action.execute(this.rowData);
    this.onEvent(event);
  }
}
