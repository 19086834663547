import { QpButtonLabelComponent } from '../qp-button/components/qp-button-label/qp-button-label.component';
import { QpButtonComponent } from '../qp-button/qp-button.component';
import { QpDotMenuComponent } from '../qp-dot-menu/qp-dot-menu.component';
import { EQpButtonSize, EQpButtonType } from '@library/components/qp-button/qp-button.models';
import { IQpDotMenuAction } from '@library/components/qp-dot-menu/qp-dot-menu.models';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { NgIf, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { head, nth } from 'lodash/index';

@Component({
  selector: 'qp-action-cell',
  templateUrl: './qp-action-cell.component.html',
  styleUrls: ['./qp-action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, QpButtonComponent, QpButtonLabelComponent, QpDotMenuComponent, TranslateModule],
})
export class QpActionCellComponent<TData = unknown> {
  public readonly buttonSizes: typeof EQpButtonSize = EQpButtonSize;
  public readonly buttonTypes: typeof EQpButtonType = EQpButtonType;

  public firstAction: QimaOptionalType<IQpDotMenuAction>;
  public secondAction: QimaOptionalType<IQpDotMenuAction>;

  @Input()
  public get actions(): IQpDotMenuAction[] {
    return this._actions;
  }

  public set actions(actions: IQpDotMenuAction[]) {
    this._actions = actions || [];
    const filteredActions: IQpDotMenuAction[] = this._actions.filter(
      (action): boolean => action?.icon !== EQpIconName.IC_DELETE && action?.icon !== EQpIconName.IC_DENY
    );

    this.firstAction = head(filteredActions);
    this.secondAction = nth(filteredActions, 1);
  }

  @Input()
  public data: QimaOptionalType<Record<string, TData>> = undefined;

  @Input()
  public hover = false;

  @Input()
  public dynamicActions = false;

  private _actions: IQpDotMenuAction[] = [];

  public onActionClick(action: IQpDotMenuAction, event: Event): void {
    action.execute(this.data);
    this.onEvent(event);
  }

  public onEvent(event: Event): void {
    event.stopPropagation();
  }
}
