<td colspan="99">
  <img
    [alt]="'global.datatable.no-result.title' | translate"
    class="builder__preview__emptycontent__image"
    src="assets/pictures/img-no-results.svg"
  />
  <h4>
    {{ 'global.datatable.no-result.title' | translate }}
  </h4>
  <p>
    {{ 'global.datatable.no-result.message' | translate }}
  </p>
</td>
