import { QpIconComponent } from '../qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EQimaPosition, EQimaTooltipStyle, QimaAutoTooltipDirectiveModule } from '@qima/ngx-qima';

@Component({
  selector: 'qp-dropdown',
  templateUrl: './qp-dropdown.component.html',
  styleUrls: ['./qp-dropdown.component.scss'],
  standalone: true,
  imports: [QimaAutoTooltipDirectiveModule, QpIconComponent, NgIf],
})
export class QpDropdownComponent {
  @Input()
  public label: string = '';

  @Input()
  public icon: EQpIconName = EQpIconName.IC_XS_CHEVRON_DOWN_FILTERS;

  @Input()
  public showTooltip: boolean = false;

  @Output()
  public readonly onDropdownClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly onDropdownOpen: EventEmitter<void> = new EventEmitter<void>();

  public readonly qimaPositions: typeof EQimaPosition = EQimaPosition;
  public readonly qimaTooltipStyles: typeof EQimaTooltipStyle = EQimaTooltipStyle;
  public isDropdownVisible = false;

  public setDropdownState(event, isVisible: Readonly<boolean>): void {
    event.stopPropagation();
    this.isDropdownVisible = isVisible;

    if (this.isDropdownVisible) {
      this.onDropdownOpen.emit();
    } else {
      this.onDropdownClose.emit();
    }
  }
}
