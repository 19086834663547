import { QpTrackByIndexDirective } from '@library/directives/qp-track-by/qp-track-by-index.directive';
import { QpRandomNumberPipe } from '@library/pipes/qp-random-number/qp-random-number.pipe';
import { NgFor } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';

const ROW_COUNT: number = 18;
const FAKE_TEXT_MIN_WIDTH: number = 40;

@Component({
  selector: 'qp-datatable-fake',
  templateUrl: './qp-datatable-fake.component.html',
  styleUrls: ['./qp-datatable-fake.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, QpTrackByIndexDirective, QpRandomNumberPipe],
})
export class QpDatatableFakeComponent {
  public readonly rowCount: Array<void> = new Array(ROW_COUNT);
  public readonly fakeTextMinWidth: number = FAKE_TEXT_MIN_WIDTH;
  public readonly columnsWidths: number[] = [10, 20, 20, 30, 10, 10];
}
