<div class="qp-datatable-fake">
  <div
    *ngFor="let row of rowCount; let index = index"
    ngForQpTrackByIndex
    class="qp-datatable-fake__row"
    [class.qp-datatable-fake__row--header]="index === 0"
  >
    <div *ngFor="let columnWidth of columnsWidths" ngForQpTrackByIndex class="qp-datatable-fake__row-cell" [style.width.%]="columnWidth">
      <span class="qp-datatable-fake__row-cell-text" [style.width.%]="index === 0 ? 100 : (100 | qpRandomNumber: fakeTextMinWidth)"></span>
    </div>
  </div>
</div>
