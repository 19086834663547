<div
  (click)="changeState($event)"
  [class.qp-checkbox--container]="!qpDisabled"
  [class.qp-checkbox--disabled]="qpDisabled"
  class="qp-checkbox"
>
  <div
    [class.qp-checkbox__checkbox--checked]="qpChecked"
    [class.qp-checkbox__checkbox--partially]="isPartiallyCheck"
    class="qp-checkbox__checkbox"
  >
    <qp-icon
      *ngIf="isPartiallyCheck"
      [class.qp-checkbox__checkbox-icon--animation]="isAnimated"
      [class.qp-checkbox__checkbox-icon--disabled]="qpDisabled"
      [name]="iconNames.IC_MINUS"
      class="qp-checkbox__checkbox--partially-icon"
    >
    </qp-icon>
    <qp-icon
      *ngIf="qpChecked"
      [name]="iconNames.IC_TICK"
      [class.qp-checkbox__checkbox-icon--animation]="isAnimated"
      [class.qp-checkbox__checkbox-icon--disabled]="qpDisabled"
      class="qp-checkbox__checkbox-icon"
    >
    </qp-icon>
  </div>
  <input [checked]="qpChecked" [ngModel]="qpChecked" [qpDisabled]="qpDisabled" class="qp-checkbox__input" type="checkbox" />
  <span (qpHasContentChange)="onContentChange($event)" [class.qp-checkbox__checkbox-content]="hasCustomContent" qpHasContent>
    <ng-content></ng-content>
  </span>
</div>
