import { QimaOptionalType } from '@qima/ngx-qima';
// eslint-disable-next-line no-restricted-syntax
import { isFinite } from 'lodash/index';

/**
 * @description
 * Useful to avoid a type error with the isFinite function returning a boolean
 * Use {@link isFinite} instead of {@link Number.isFinite} because {@link Number.isFinite} only accept a number
 * @param {QimaOptionalType<string | number>} value The value to check if not finite
 * @returns {boolean} True if the value is not a finite number
 */
export function qpIsFiniteNumber(value: QimaOptionalType<string | number>): value is number {
  return isFinite(value);
}
