import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qpRandomNumber',
  standalone: true,
})
export class QpRandomNumberPipe implements PipeTransform {
  public transform(max: Readonly<number>, min: Readonly<number> = 0): number {
    if (min > max) {
      max = min;
      min = 0;
    }

    return Math.floor(Math.random() * (max - min)) + min;
  }
}
