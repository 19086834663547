<button (click)="setDropdownState($event, true)" class="dropdown">
  <span
    class="dropdown__label"
    data-test-id="dropdown-label"
    qimaAutoTooltip
    [qimaAutoTooltipStyle]="qimaTooltipStyles.DARK"
    [qimaAutoTooltipPosition]="qimaPositions.BOTTOM_LEFT"
    [qimaAutoTooltipText]="label"
    [qimaAutoTooltipIsVisible]="showTooltip && !isDropdownVisible"
  >
    {{ label }}
  </span>
  <qp-icon [name]="icon" class="dropdown__icon"></qp-icon>
  <ng-container *ngIf="isDropdownVisible">
    <div (click)="setDropdownState($event, false)" class="dropdown__overlay"></div>
    <div (click)="$event.stopPropagation()" class="dropdown__content">
      <ng-content></ng-content>
    </div>
  </ng-container>
</button>
