import { ETableCustomType } from '@library/dto/table/table.dto';
import { Params } from '@angular/router';

export enum EQpTableCellSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum EQpTableName {
  INSPECTION = 'INSPECTION',
  REPORT = 'REPORT',
  PRODUCT = 'PRODUCT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export enum EQpTableColumnMode {
  DEFAULT = 'DEFAULT',
  STICKY_LEFT = 'STICKY_LEFT',
  STICKY_RIGHT = 'STICKY_RIGHT',
}

export interface IQpTable {
  hasSelection?: boolean;
  hasActions?: boolean;
  columns: IQpTableColumn[];
}

export interface IQpTableColumn {
  key: EQpTableColumnIdentifier | string; // 💡 Can be a string to manage custom types
  label: string;
  header?: IQpTableHeader;
  size: EQpTableCellSize;
  columnMode?: EQpTableColumnMode;
  isDefault?: boolean;
  isVisible?: boolean;
  isCustom?: boolean;
  customType?: ETableCustomType;
}

export interface IQpTableHeader {
  propertyName: EQpTableColumnFilterName | string; // 💡 Can be a string to manage custom types
  actions: IQpTableHeaderAction[];
}

export interface IQpTableHeaderAction {
  type: EQpTableConfigurationType;
  values?: IQpTableHeaderActionEntry[];
  exactSearch?: boolean;
}

export enum EQpTableColumnIdentifier {
  RESULT = 'RESULT',
  STATUS = 'STATUS',
  DECISION = 'DECISION',
  INSPECTOR = 'INSPECTOR',
  INSPECTOR_COMPANY = 'INSPECTOR_COMPANY',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PO_REFERENCE = 'PO_REFERENCE',
  INSPECTION_TYPE = 'INSPECTION_TYPE',
  INSPECTION_SITE = 'INSPECTION_SITE',
  INSPECTION_DATE = 'INSPECTION_DATE',
  CORRECTIVE_ACTION = 'CORRECTIVE_ACTION',
  PRODUCT_REFERENCE = 'PRODUCT_REFERENCE',
  PRODUCT_MAIN_IDENTIFIER = 'PRODUCT_MAIN_IDENTIFIER',
  SHIPMENT_DATE = 'SHIPMENT_DATE',
  PLANNED_DATE = 'PLANNED_DATE',
  ADDITIONAL_COMMENT = 'ADDITIONAL_COMMENT',
  REFERENCE = 'REFERENCE',
  TIER_ONE_NAME = 'TIER_ONE_NAME',
  TIER_TWO_NAME = 'TIER_TWO_NAME',
  ORDER_DATE = 'ORDER_DATE',
  SELECTION_CHECKBOX = 'SELECTION_CHECKBOX',
  PRODUCT_SKU = 'PRODUCT_SKU',
  PRODUCT_GTIN = 'PRODUCT_GTIN',
  ENTITY_RESPONSIBLE = 'ENTITY_RESPONSIBLE',
  ENTITY_RESPONSIBLE_PO = 'ENTITY_RESPONSIBLE_PO',
  PRODUCTION_SITE = 'PRODUCTION_SITE',
  DESTINATION = 'DESTINATION',
  CREATOR = 'CREATOR',
  CREATION_DATE = 'CREATION_DATE',
  CREATED_DATE = 'CREATED_DATE',
  IDENTIFIER_VALUE = 'IDENTIFIER_VALUE',
  NAME = 'NAME',
  TAGS = 'TAGS',
  CATEGORY_NAME = 'CATEGORY_NAME',
  SKU = 'SKU',
  GTIN = 'GTIN',
  DESCRIPTION = 'DESCRIPTION',
  PICTURE = 'PICTURE',
  MEASUREMENTS = 'MEASUREMENTS',
  ORIGIN = 'ORIGIN',
  DECISION_COMMENT = 'DECISION_COMMENT',
  REJECTION_REASONS = 'REJECTION_REASONS',
  // Identifiers below are not validated with the backend since they are not used on customizable tables
  PRIORITY = 'PRIORITY',
  SUMMARY = 'SUMMARY',
  EXPECTED_COMPLETION_DATE = 'EXPECTED_COMPLETION_DATE',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  LAST_MODIFIED_DATE = 'LAST_MODIFIED_DATE',
  PRODUCT_IDENTIFIER_VALUE = 'PRODUCT_IDENTIFIER_VALUE',
  CLIENT_NAME = 'CLIENT_NAME',
  INVITATION_STATUS = 'INVITATION_STATUS',
  COUNTRY_NAME = 'COUNTRY_NAME',
  CUSTOM_ID = 'CUSTOM_ID',
  ENTITY_TYPE = 'ENTITY_TYPE',
  ENTITY_NAME = 'ENTITY_NAME',
  BRAND_NAME = 'BRAND_NAME',
  WORKFLOW_NAME = 'WORKFLOW_NAME',
  PUBLICATION_STATUS = 'PUBLICATION_STATUS',
  IS_QIMA = 'IS_QIMA',
  CHECKLIST_TYPE = 'CHECKLIST_TYPE',
  CHECKLIST_NAME = 'CHECKLIST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  ROLE = 'ROLE',
  RISK = 'RISK',
  FILE_TYPE = 'FILE_TYPE',
  RATING_RESULTS = 'RATING_RESULTS',
  CHECKLIST_RATING_SYSTEM = 'CHECKLIST_RATING_SYSTEM',
  REPORT_GROUP_ID = 'REPORT_GROUP_ID',
  INSPECTION_ID = 'INSPECTION_ID',
  QUANTITY = 'QUANTITY',
  CUSTOM_ACTION = 'CUSTOM_ACTION',
}

// 💡 : Backend filter keys
export enum EQpTableColumnFilterName {
  RESULT = 'result',
  STATUS = 'status',
  PRODUCT_SKUS = 'productSkus',
  PRODUCT_GTINS = 'productGtins',
  REFERENCE = 'reference',
  ORDER_DATE = 'orderDate',
  TYPE_LABEL = 'typeLabel',
  ENTITY_NAME = 'entityName',
  REPORT_DECISION = 'reportDecision',
  TIER_ONE_NAME = 'tier1Name',
  TIER_TWO_NAME = 'tier2Name',
  DESTINATION_NAME = 'destinationName',
  SHIPMENT_DATE = 'shipmentDate',
  PLANNED_DATE = 'plannedDate',
  CREATED_DATE = 'createdDate',
  CLIENT_NAME = 'clientName',
  PRODUCT_NAMES = 'productNames',
  CREATED_BY_FULL_NAME = 'createdByFullName',
  INSPECTION_DATE = 'inspectionDate',
  PRODUCT_REFERENCES = 'productReferences',
  INSPECTOR_FULL_NAME = 'inspectorFullName',
  OVERALL_CAP_STATUS = 'overallCapStatus',
  PRODUCT_SHIPMENT_DATES = 'productShipmentDates',
  ADDITIONAL_COMMENTS = 'additionalComment',
  PURCHASE_ORDER_REFERENCES = 'purchaseOrderReferences',
  PRODUCT_IDENTIFIER_VALUES = 'productIdentifierValues',
  PRODUCT_IDENTIFIER_VALUE = 'productIdentifierValue',
  PRODUCT_BRAND_NAMES = 'productBrandNames',
  PURCHASE_ORDER_TIER_ONE_NAME = 'purchaseOrderTier1Name',
  PURCHASE_ORDER_TIER_TWO_NAME = 'purchaseOrderTier2Name',
  SKU = 'sku',
  GTIN = 'gtin',
  DESCRIPTION = 'description',
  INSPECTOR_COMPANY_NAME = 'inspectorCompanyName',
  HAS_MEASUREMENTS = 'hasMeasurements',
  ORIGIN = 'origin',
  REPORT_DECISION_COMMENT = 'reportDecisionComment',
  REPORT_DECISION_REJECT_REASONS = 'reportDecisionRejectReasons',

  PRIORITY = 'priority',
  SUMMARY = 'summary',
  PURCHASE_ORDER_REFERENCE = 'purchaseOrderReference',
  PRODUCT_MAIN_IDENTIFIER_VALUE = 'productMainIdentifierValue',
  EXPECTED_COMPLETION_DATE = 'expectedCompletionDate',
  IDENTIFIER_VALUE = 'identifierValue',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  CATEGORY_NAME = 'categoryName',
  NAME = 'name',
  TAGS = 'tags',
  TYPE_ID = 'typeId',
  CUSTOM_ID = 'customId',
  COUNTRY_NAME = 'countryName',
  CREATED_BY = 'createdBy',
  INVITATION_STATUS = 'invitationStatus',
  BRAND_NAME = 'brandName',
  INSPECTION_TYPE_LABEL = 'inspectionTypeLabel',
  IS_QIMA = 'isQima',
  TYPE = 'type',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ROLE = 'role',
  RISK = 'risk',
  FILE_NAME = 'fileName',
  RATING_RESULTS = 'ratingResults',
  REPORT_GROUP_ID = 'reportGroupIds',
  REPORT_GROUP_INSPECTION_ID = 'inspectionId',
  INSPECTION_ID = 'id',
}

export interface IQpTableHeaderActionEntry {
  label: string;
  value: string;
  params?: unknown;
}

export enum EQpTableConfigurationType {
  FILTER = 'FILTER',
  SEARCH = 'SEARCH',
  SORT = 'SORT',
  BETWEEN = 'BETWEEN',
}

export enum EQpTableFilterType {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  IN = 'IN',
  BETWEEN = 'BETWEEN',
}

export interface IQpTableFilterAndSortParam extends Params {
  type: string;
  value: string;
}

export interface IQpTableFiltersAndSorts {
  [property: string]: IQpTableFilterAndSort;
}

export interface IQpTableFilterAndSort {
  sort?: 'ASC' | 'DESC';
  search?: string;
  exactSearch?: boolean;
  filter?: string[];
  between?: IQpTableFilterAndSortBetween;
}

export interface IQpTableFilterAndSortBetween {
  from?: string;
  to?: string;
}
