import { IQpDatatableFilterAndSortParam, IQpDatatableFilterAndSort } from '@library/components/qp-datatable/qp-datatable.models';
import { EQpTableFilterType } from '@library/components/qp-table/qp-table.models';

/**
 * @param {IQpDatatableFilterAndSortParam} filter The filter to transform
 */
export function qpTransformFilterToQpTableConfig(filter: IQpDatatableFilterAndSortParam): IQpDatatableFilterAndSort {
  const result: IQpDatatableFilterAndSort = {};
  const filterValue: string = filter.value;
  const command: string = filterValue?.split(',')[1];
  const value: string = filterValue?.split(',')[2];

  switch (command) {
    case EQpTableFilterType.CONTAINS: {
      result['search'] = value;
      result['exactSearch'] = false;
      break;
    }

    case EQpTableFilterType.EQUALS: {
      result['search'] = value;
      result['exactSearch'] = true;
      break;
    }

    case EQpTableFilterType.IN: {
      const inSplitResult = filterValue?.split('IN,')[1];
      const inValue = inSplitResult.substring(1, inSplitResult.length - 1).split(',');

      result['filter'] = inValue;
      break;
    }

    case EQpTableFilterType.BETWEEN: {
      const betweenSplitResult = filterValue?.split('BETWEEN,')[1];
      const [from, to] = betweenSplitResult.substring(1, betweenSplitResult.length - 1).split(',');

      result['between'] = {
        from,
      };

      if (to !== '5000-01-01') {
        result['between']['to'] = to;
      }

      break;
    }
  }

  return result;
}
