export interface ITableColumnDTO {
  key: string;
  label?: string;
  visible: boolean;
  default: boolean;
  sticky: boolean;
  type?: ETableCustomType;
  custom: boolean;
}

export enum ETableCustomType {
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
}

export interface ITableColumnsDTO {
  columns: ITableColumnDTO[];
}
