// @ts-strict-ignore
import { IQpDatatableFiltersAndSorts } from '@library/components/qp-datatable/qp-datatable.models';
import { qpHasFilterAndSort } from '@library/functions/table/qp-has-filter-and-sort';
import { Pipe, PipeTransform } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Pipe({
  name: 'qpHasFilterAndSort',
  pure: false,
  standalone: true,
})
export class QpHasFilterAndSortTablePipe implements PipeTransform {
  public transform(filtersAndSorts: Readonly<IQpDatatableFiltersAndSorts>, name: Readonly<QimaOptionalType<string>>): boolean {
    return qpHasFilterAndSort(filtersAndSorts[name]);
  }
}
