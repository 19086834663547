import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpHasFilters } from '@library/functions/filters/qp-has-filters';
import { qpAreFiltersAndSorts } from '@library/functions/sorts/qp-are-filters-and-sorts';
import { qpGetSorts } from '@library/functions/sorts/qp-get-sorts';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { Params } from '@angular/router';

export class ParamsBuilder implements Params {
  public with<T extends Record<string, unknown>>(data: T | IQpDatatableFilterAndSortParam[]): ParamsBuilder {
    if (qpAreFiltersAndSorts<T>(data)) {
      return this._withFiltersAndSorts(data);
    }

    return this._withAny(data);
  }

  private _withAny(data: Record<string, unknown> = {}): ParamsBuilder {
    Object.keys(data).forEach((key): void => {
      this[key] = data[key];
    });

    return this;
  }

  private _withFiltersAndSorts(filtersAndSorts: IQpDatatableFilterAndSortParam[] = []): ParamsBuilder {
    if (filtersAndSorts.length > 0) {
      if (qpHasFilters(filtersAndSorts)) {
        (this as Params).filter = qpGetFilters(filtersAndSorts).map((filter): string => filter.value);
      }

      if (qpHasSorts(filtersAndSorts)) {
        (this as Params).sort = qpGetSorts(filtersAndSorts).map((sort): string => sort.value);
      }
    }

    return this;
  }
}
