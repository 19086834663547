import { QpActionCellComponent } from '../qp-action-cell/qp-action-cell.component';
import { IQpDotMenuAction } from '@library/components/qp-dot-menu/qp-dot-menu.models';
import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, HostListener } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'qp-datarow',
  templateUrl: './qp-datarow.component.html',
  styleUrls: ['./qp-datarow.component.scss'],
  standalone: true,
  imports: [NgIf, QpActionCellComponent],
})
export class QpDatarowComponent<TData = unknown> {
  public rowHover = false;

  @Input()
  public actions: IQpDotMenuAction[] = [];

  @Input()
  public data: QimaOptionalType<Record<string, TData>> = undefined;

  @Input()
  public dynamicActions = false;

  @Input()
  public showMultiline = false;

  @HostBinding('class.disabled')
  @Input()
  public disabled = false;

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.rowHover = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.rowHover = false;
  }
}
