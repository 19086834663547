<div
  (click)="onEvent($event)"
  [(nzPopoverVisible)]="visible"
  [nzPopoverContent]="actionContent"
  [nzPopoverPlacement]="placement"
  class="action-menu"
  nz-popover
  nzPopoverTrigger="click"
>
  <em alt="options" nz-icon nzType="ic-more"></em>
</div>

<ng-template #actionContent>
  <div class="actions">
    <a
      (click)="actionClick(action, $event)"
      *ngFor="let action of actions"
      [ngClass]="action.disabled ? 'disabled' : 'active'"
      class="action"
    >
      <em *ngIf="action.icon" [nzType]="action.icon" alt="options" nz-icon></em>
      {{ action.label | translate }}
    </a>
  </div>
</ng-template>
