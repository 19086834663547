import { EQpDatePipeMode } from '@library/pipes/qp-date/qp-date.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'qpDate',
  standalone: true,
})
export class QpDatePipe implements PipeTransform {
  private readonly _datePipe: DatePipe | undefined;
  private readonly _localizedDatePipe: DatePipe | undefined;

  public constructor(
    private readonly _translate: TranslateService,
    private readonly _dateService: QpDateService
  ) {
    this._datePipe = new DatePipe('en');
    this._localizedDatePipe = new DatePipe(this._translate.currentLang);
  }

  public transform(value: Date | string | number, mode: EQpDatePipeMode = EQpDatePipeMode.DDMMMYYYY, timezone?: string): string {
    if (mode === EQpDatePipeMode.HUMAN) {
      return this._humanFormat(new Date(value));
    }

    return this._datePipe?.transform(value, mode, timezone) ?? value?.toString();
  }

  private _humanFormat(value: Date): string {
    const today = new Date(Date.now());
    const tomorrow = new Date();
    const todayLabel = 'common.date.today';
    const tomorrowLabel = 'common.date.tomorrow';

    tomorrow.setDate(today.getDate() + 1);

    if (this._dateService.isSameDay(today, value)) {
      return this._translate.instant(todayLabel);
    } else if (this._dateService.isSameDay(tomorrow, value)) {
      return this._translate.instant(tomorrowLabel);
    } else if (this._dateService.isSameWeek(value, today)) {
      return this._localizedDatePipe?.transform(value, EQpDatePipeMode.EEEE) ?? value?.toString();
    }

    return this._datePipe?.transform(value, EQpDatePipeMode.DDMMMYYYY) ?? value?.toString();
  }
}
