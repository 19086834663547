import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { IQpLocalStorageFiltersAndSortsQueryParams } from '@library/models/qp-local-storage-filters-and-sorts-query-params.models';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isEmpty } from 'lodash/index';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class QpGetFilterAndSortParamsGuard implements CanActivate {
  public constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _qpQueryParamsService: QpQueryParamsService
  ) {}

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
    const { url, query } = this._qpQueryParamsService.getParsedUrl(state.url);
    const hasQueryParamsFilled = !isEmpty(query);

    if (hasQueryParamsFilled) {
      return true;
    }

    const localstorageQueryParams: IQpLocalStorageFiltersAndSortsQueryParams =
      this._localStorageService.retrieve(QP_FILTERS_AND_SORTS_QUERY_PARAMS)?.[url];

    if (!localstorageQueryParams) {
      return true;
    }

    return this._router.createUrlTree([url], { queryParams: localstorageQueryParams });
  }
}
