import { IQpDatatableFilterAndSort } from '@library/components/qp-datatable/qp-datatable.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil } from 'lodash/index';

/**
 * @param filterAndSort
 */
export function qpHasFilterAndSort(filterAndSort: QimaOptionalType<IQpDatatableFilterAndSort>): boolean {
  return (
    !isNil(filterAndSort) &&
    (!isNil(filterAndSort.search) || !isNil(filterAndSort.sort) || !isNil(filterAndSort.between) || !isEmpty(filterAndSort.filter))
  );
}
