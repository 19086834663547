export interface IQpConsultationPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  totalItems: number;
  totalPageCount: number;
  hasMoreItems(): boolean;
  getMoreItemsCount(): number;
}

export const EMPTY_QP_CONSULTATION_PAGE: Readonly<IQpConsultationPage<never>> = {
  items: [],
  page: 0,
  pageSize: 0,
  totalItems: 0,
  totalPageCount: 0,
  hasMoreItems(): boolean {
    return false;
  },
  getMoreItemsCount(): number {
    return 0;
  },
};

export class QpConsultationPage<T> implements IQpConsultationPage<T> {
  public static create<T>(consultationPage: {
    items: T[];
    page: number;
    pageSize: number;
    totalItems: number;
    totalPageCount: number;
  }): IQpConsultationPage<T> {
    return new QpConsultationPage<T>(
      consultationPage.items,
      consultationPage.page,
      consultationPage.pageSize,
      consultationPage.totalItems,
      consultationPage.totalPageCount
    );
  }

  public constructor(
    public items: T[],
    public page: number,
    public pageSize: number,
    public totalItems: number,
    public totalPageCount: number
  ) {}

  public hasMoreItems(): boolean {
    return this.getMoreItemsCount() > 0;
  }

  public getMoreItemsCount(): number {
    return this.totalItems - ((this.page - 1) * this.pageSize + this.items.length);
  }
}
