<ng-content></ng-content>
<td *ngIf="!showMultiline" class="actions-column">
  <qp-action-cell
    [actions]="actions"
    [data]="data"
    [dynamicActions]="dynamicActions"
    [hover]="rowHover"
    class="actions-column__content"
  ></qp-action-cell>
</td>
