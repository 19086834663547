// @ts-strict-ignore
import { SERVER_API_URL } from '@admin/app/app.constants';
import { createRequestOption } from '@admin/app/shared/util/request-util';
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpAsHttpParams } from '@library/functions/qp-as-http-params/qp-as-http-params';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { IQpBrand, QpBrand } from '@library/models/qp-brand.models';
import { IQpConsultationPage, QpConsultationPage } from '@library/models/qp-consultation-page.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BrandService {
  public globalResourceUrl = `${SERVER_API_URL}api/brands`;
  public adminResourceUrl = `${SERVER_API_URL}api/admin/brands`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public getBrands$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = [],
    resourceType: 'ADMIN' | 'GLOBAL' = 'ADMIN'
  ): Observable<IQpConsultationPage<QpBrand>> {
    const filtersAndSortsWithDefaults: IQpDatatableFilterAndSortParam[] = this.applyDefaultSortsIfNone(filtersAndSorts, [
      { type: 'sort', value: 'id,asc' },
    ]);
    const params = qpAsHttpParams(pageQuery, filtersAndSortsWithDefaults);
    const url = resourceType === 'ADMIN' ? this.adminResourceUrl : this.globalResourceUrl;

    return this._httpClient.get<QpBrand[]>(url, { params, observe: 'response' }).pipe(
      map((response): IQpConsultationPage<QpBrand> => {
        const items: QpBrand[] = response.body;
        const totalItems = parseInt(response.headers.get('x-total-count'));
        const totalPageCount = parseInt(response.headers.get('x-total-page'));

        return QpConsultationPage.create<QpBrand>({
          items,
          page: pageQuery.page + 1,
          pageSize: pageQuery.size,
          totalItems,
          totalPageCount,
        });
      })
    );
  }

  public getBrand$(brandId: number): Observable<QimaOptionalType<QpBrand>> {
    return this._httpClient
      .get<QimaOptionalType<QpBrand>>(`${this.globalResourceUrl}/${brandId}`, { observe: 'response' })
      .pipe(map((response): QimaOptionalType<QpBrand> => response.body));
  }

  public applyDefaultSortsIfNone(
    filtersAndSorts: IQpDatatableFilterAndSortParam[],
    defaultSorts: IQpDatatableFilterAndSortParam[] = []
  ): IQpDatatableFilterAndSortParam[] {
    const copy: IQpDatatableFilterAndSortParam[] = !qpHasSorts(filtersAndSorts)
      ? [...qpGetFilters(filtersAndSorts), ...defaultSorts]
      : [...filtersAndSorts];

    return copy;
  }

  public query(req?: { [key: string]: unknown }, resourceType: 'ADMIN' | 'GLOBAL' = 'ADMIN'): Observable<HttpResponse<IQpBrand[]>> {
    const options = createRequestOption(req);
    let url: string;

    switch (resourceType) {
      case 'ADMIN':
        url = this.adminResourceUrl;
        break;
      case 'GLOBAL':
        url = this.globalResourceUrl;
        break;
    }

    return this._httpClient.get<IQpBrand[]>(url, { params: options, observe: 'response' });
  }

  public create$(brand: IQpBrand, logo: QimaOptionalType<Blob>, reportHeaderLogo: QimaOptionalType<Blob>): Observable<IQpBrand> {
    const formData = new FormData();

    formData.append('brand', new Blob([JSON.stringify(brand)], { type: 'application/json' }));

    if (logo) {
      formData.append('logo', logo);
    }

    if (reportHeaderLogo) {
      formData.append('reportHeaderLogo', reportHeaderLogo);
    }

    return this._httpClient.post<IQpBrand>(this.adminResourceUrl, formData, { observe: 'body' });
  }

  public update$(
    brandId: number,
    brand: IQpBrand,
    logo: QimaOptionalType<Blob>,
    reportHeaderLogo: QimaOptionalType<Blob>
  ): Observable<IQpBrand> {
    const formData = new FormData();

    formData.append('brand', new Blob([JSON.stringify(brand)], { type: 'application/json' }));

    if (logo) {
      formData.append('logo', logo);
    }

    if (reportHeaderLogo) {
      formData.append('reportHeaderLogo', reportHeaderLogo);
    }

    return this._httpClient.put<IQpBrand>(`${this.adminResourceUrl}/${brandId}`, formData, { observe: 'body' });
  }

  public getAllBrands(req?: { [key: string]: unknown }): Observable<IQpBrand[]> {
    const options = createRequestOption(req);

    return this._httpClient.get<IQpBrand[]>(this.adminResourceUrl, { params: options, observe: 'body' });
  }

  public getBrandById$(id: number): Observable<IQpBrand> {
    return this._httpClient.get<IQpBrand>(`${this.globalResourceUrl}/${id}`);
  }

  public getBrandReportHeaderLogo$(id: number): Observable<Blob> {
    return this._httpClient.get(`${this.globalResourceUrl}/${id}/report-header-logo`, { responseType: 'blob' });
  }

  public deleteBrandReportHeaderLogo$(brandId: number): Observable<void> {
    return this._httpClient.delete<void>(`${this.adminResourceUrl}/${brandId}/report-header-logo`);
  }

  public getBrandLogo$(id: number): Observable<Blob> {
    return this._httpClient.get(`${this.globalResourceUrl}/${id}/logo`, { responseType: 'blob' });
  }

  public deleteBrandLogo$(brandId: number): Observable<void> {
    return this._httpClient.delete<void>(`${this.adminResourceUrl}/${brandId}/logo`);
  }
}
